
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'app-restaurant-list-page',
  data() {
    return {
      page: parseInt(this.$route.query.page) || 1,
      action: 'next',
    };
  },
  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      is_labtop: 'layout/labtop',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_ipad_pro: 'layout/is_screen_ipad_pro',
      rows: 'restaurant/view/rows',
      current: 'branch/view/branch',
      loading: 'restaurant/view/loading',
      pagination: 'restaurant/view/pagination',
    }),
    isDark() {
      return this.$q.dark.isActive;
    },
    isRTL() {
      return this.$i18n.locale == 'ar';
    },
    paginationPage() {
      return this.page - 1 || 0;
    },
  },

  methods: {
    ...mapActions({
      doFetch: 'restaurant/view/doFetch',
    }),
    next() {
      this.page++;
      this.action = 'next';
    },
    prev() {
      this.page--;
      this.action = 'prev';
    },
  },
  watch: {
    current() {
      this.page = 1;
      this.doFetch({
        branchId: this.current,
        action: 'next',
        currentPage: 0,
      });
    },
    async page() {
      this.$router.push({
        path: 'restaurant',
        query: { page: this.page },
      });
      await this.doFetch({
        branchId: this.current,
        action: this.action,
        currentPage: this.paginationPage,
      });
      console.log('page', this.page);
    },

    // '$route.query.page': {
    //   handler: function () {
    //     this.page = this.$route.query.page || 1;
    //   },
    //   deep: true,
    //   immediate: true,
    // },
  },
  async mounted() {
    await this.doFetch({
      branchId: this.current,
      action: 'next',
      currentPage: this.paginationPage,
    });
    console.log(
      'mounted',
      parseInt(this.$route.query.page),
    );
  },
};
